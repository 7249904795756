<template>
  <div class="modalPopup print_modal ledger_print_modal" v-if="modalClose">
    <div class="modal_header">
      <h2 class="title">
        {{ `${checkPage} 원장` }}
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li v-if="checkPage == '원자재'">
          <h6>자재 종류</h6>
          <span>{{ convertCheck }}</span>
        </li>
        <li v-else-if="checkPage == '제품'">
          <h6>제품 유형</h6>
          <span>{{ convertCheck }}</span>
        </li>
        <li>
          <h6 v-if="checkPage == '원자재' || checkPage == '제품'">
            <span>{{ checkIn == '' ? '전체' : checkIn }} </span>
            {{ checkPage }}
          </h6>
          <h6 v-else-if="checkPage == '구매처' || checkPage == '매출처'">
            {{ checkPage }}
          </h6>
          <span v-for="item in include_list" :key="item.id">
            {{
              checkPage == '구매처'
                ? findInfoFromId(companys, item).name
                : checkPage == '원자재'
                ? findInfoFromId(material, item.id).name
                : checkPage == '제품'
                ? findInfoFromId(products, item.id).name
                : checkPage == '매출처'
                ? findInfoFromId(companys, item).name
                : ''
            }}</span
          >
        </li>
        <li>
          <h6>조회기간</h6>
          {{ `${startDate} ~ ${endDate}` }}
        </li>
      </ul>
    </div>
    <div class="modal_body"></div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  props: [
    'checkPage',
    'checkIn',
    'include_list',
    'startDate',
    'endDate',
    'filterCheck',
  ],
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
      checkList: [],
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      material: 'getMaterial',
      resource_type: 'getResourceType',
    }),
    convertCheck() {
      let clone = this.lodash.clonedeep(this.filterCheck);
      clone.splice(0, 1);
      clone = clone.map(x => {
        x = this.findInfoFromId(this.resource_type, x).name;
        return x;
      });
      let cloneList = clone.toString().replace(/,/gi, ', ');
      return cloneList;
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const ledgerHeader = document.querySelector(
        '.ledger_print_modal .modal_header',
      );
      const ledgerTable = document.querySelector(
        '.ledger_print_modal .modal_body .mes_tbl_wrap',
      );
      const printDiv = document.createElement('div');
      let ledgerHeaderClone = ledgerHeader.cloneNode(true);
      let ledgerTableClone = ledgerTable.cloneNode(true);
      let ledgerTrClone = ledgerTableClone.querySelectorAll('tr');
      ledgerTrClone.forEach(el => {
        let ledgerTd = el.querySelectorAll('td');
        ledgerTd.forEach((el2, index) => {
          index == 1 ? (el2.innerText = el2.innerText.substr(6, 7)) : '';
        });
      });
      printDiv.className = 'ledger_print_wrap';
      document.body.appendChild(printDiv);
      printDiv.append(ledgerHeaderClone, ledgerTableClone);
      app.style.display = 'none';
      window.print();
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
  },
  mounted() {
    if (this.checkPage == '원자재') {
      let ledgerTable = document.querySelector('.mat_print_table');
      let ledgerModal = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTableClone = ledgerTable.cloneNode(true);
      ledgerModal.appendChild(ledgerTableClone);
    } else if (this.checkPage == '구매처') {
      let ledgerModal = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTable = document.querySelector('.pur_print_table');
      let ledgerTableClone = ledgerTable.cloneNode(true);
      ledgerModal.appendChild(ledgerTableClone);
    } else if (this.checkPage == '제품') {
      let ledgerTable = document.querySelector('.product_print_table');
      let ledgerModal = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTableClone = ledgerTable.cloneNode(true);
      ledgerModal.appendChild(ledgerTableClone);
    } else if (this.checkPage == '매출처') {
      let ledgerModal = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTable = document.querySelector('.sales_print_table');
      let ledgerTableClone = ledgerTable.cloneNode(true);
      ledgerModal.appendChild(ledgerTableClone);
    }
  },
  async created() {
    await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    await this.FETCH('FETCH_COMPANY', '구매처');
    await this.FETCH('FETCH_PRODUCT', '제품');
    if (this.resource_type.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 유형');
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
